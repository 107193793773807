import React, { useEffect, useState } from 'react';
import { InteractionT } from '../../../../types/Interaction.ts';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { useEntryList } from '../../hooks/useEntryList.ts';
import { EntryListTable } from '../EntryListTable';
import { EntryListResponse, exportEntriesToCsv } from '../../service/EntryService.ts';
import { useNavigate } from 'react-router';
import { DeleteInteractionEntries } from '../DeleteInteractionEntries';
import { NewElementHeader } from '../../../commons/components/NewElementHeader';
import { translate } from '../../../../translate';
import { MdDownload } from 'react-icons/md';
import { AlertBox } from '../../../commons/components/AlertBox';
import { TagLabel } from '../../../commons/components/TagLabel';
import { Input } from '../../../commons/components/Input/Input.tsx';
import { useInteraction } from '../../../interaction/hooks/useInteraction.ts';
import { IconButton } from '../../../commons/components/IconButton';
import { Tooltip } from '../../../commons/components/Tooltip';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
}

export const EntryList: React.FC<Props> = ({ presentationId, interactionId }) => {
	const [exportingToCsv, setExportingToCsv] = useState(false);
	const [query, setQuery] = useState<string>('');
	const [lastIdSeenPage, setLastIdSeenPage] = useState(-1);
	const [allData, setAllData] = useState<EntryListResponse['entries']>([]);
	const { data: interaction } = useInteraction(presentationId, interactionId);
	const { data, isError, refetch } = useEntryList(presentationId, interactionId, { query, lastIdSeenPage });
	const navigate = useNavigate();

	useEffect(() => {
		if (data) {
			//Append new page
			setAllData((prevState) => [...prevState, ...data.entries]);
		}
	}, [data]);

	const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setQuery(event.target.value.trim());
		setLastIdSeenPage(-1);
		setAllData([]);
	};

	const handleOnPageChange = (lastIdSeenPage: number) => {
		setLastIdSeenPage(lastIdSeenPage);
	};

	const handleExportToCsv = async () => {
		try {
			setExportingToCsv(true);
			await exportEntriesToCsv(presentationId, interactionId, `entries-${data?.interaction.title}.csv`);
		} finally {
			setExportingToCsv(false);
		}
	};

	return (
		<div className='flex flex-col mx-8 gap-4'>
			{isError ? <AlertBox title={`${translate('Error fetching')} entries`} status='error' /> : null}
			<div className='flex flex-col justify-center items-center'>
				<NewElementHeader title={translate('Moderate answers')} onGoBack={() => navigate(`/presentations/${presentationId}/slides/${interaction?.slide.id}`)} />
				<TagLabel>
					<span className='text-lg'>{interaction?.title}</span>
				</TagLabel>
			</div>
			<div className='flex items-center justify-start gap-4'>
				<div className='flex w-[80%]'>
					<Input placeholder='Search' onChange={handleOnSearch} value={query} />
				</div>
				<Tooltip text='Export to csv'>
					<IconButton isLoading={exportingToCsv} aria-label='export-csv' icon={<MdDownload />} onClick={handleExportToCsv} />
				</Tooltip>
				<DeleteInteractionEntries presentationId={presentationId} interactionId={interactionId} onAfterDeletion={refetch} />
			</div>
			<div className='flex flex-col'>
				<EntryListTable presentationId={presentationId} interaction={interaction} entries={allData} onNewPage={(page) => handleOnPageChange(page)} />
			</div>
		</div>
	);
};
