import React, { useEffect, useRef } from 'react';
import { EntryListResponse } from '../../service/EntryService.ts';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { InteractionT, InteractionTypeT } from '../../../../types/Interaction.ts';
import { CategorisedTableRow } from './_/CategorisedTableRow.tsx';
import { NumberTableRow } from './_/NumberTableRow.tsx';
import { OpenEndedTableRow } from './_/OpenEndedTableRow.tsx';
import { IconicTableRow } from './_/IconicTableRow.tsx';
import { ValuationTableRow } from './_/ValuationTableRow.tsx';
import { ImageTableRow } from './_/ImageTableRow.tsx';
import { DrawingTableRow } from './_/DrawingTableRow.tsx';
import { LinkTableRow } from './_/LinkTableRow.tsx';
import {
	CategorisedEntry,
	DrawingEntry,
	IconicEntry,
	ImageEntry,
	LinkEntry,
	MultipleChoiceEntry,
	NumberEntry,
	OpenEndedEntry,
	OpenEndedLongEntry,
	ValuationEntry,
} from '../../model/entry.ts';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import isEmpty from 'lodash/isEmpty';
import { OpenEndedLongTableRow } from './_/OpenEndedLongTableRow.tsx';
import { MultipleChoiceTableRow } from './_/MultipleChoiceTableRow.tsx';

interface Props {
	presentationId: BasicPresentationT['id'];
	entries: EntryListResponse['entries'];
	interaction?: InteractionT;
	onNewPage: (page: number) => void;
}

export const EntryListTable: React.FC<Props> = ({ presentationId, interaction, entries, onNewPage }) => {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		// Create an intersection observer to detect when the last element is visible
		observerRef.current = new IntersectionObserver(
			(intersectionEntries) => {
				if (intersectionEntries[0].isIntersecting) {
					const lastEntry = entries[entries.length - 1];
					if (lastEntry) {
						onNewPage(lastEntry.id);
					}
				}
			},
			{ threshold: 0.01 }
		);

		// Observe the last row in the list
		const lastRow = containerRef.current?.querySelector('tbody > tr:last-child');
		if (lastRow) {
			observerRef.current?.observe(lastRow);
		}

		return () => {
			if (observerRef.current && lastRow) {
				observerRef.current?.unobserve(lastRow);
			}
		};
	}, [entries]);

	return (
		<TableContainer overflowY='scroll' maxH='55svh' ref={containerRef}>
			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th width='16'>Show</Th>
						<Th>Response</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{!isEmpty(interaction) && !isEmpty(entries) ? (
						<>
							{interaction.type === InteractionTypeT.NUMBER ? (
								<NumberTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as NumberEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.OPEN_ENDED ? (
								<OpenEndedTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as OpenEndedEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.OPEN_ENDED_LONG ? (
								<OpenEndedLongTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as OpenEndedLongEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.MULTIPLE_CHOICE ? (
								<MultipleChoiceTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as MultipleChoiceEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.ICONIC ? (
								<IconicTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as IconicEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.CATEGORISED ? (
								<CategorisedTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as CategorisedEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.VALUATION ? (
								<ValuationTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as ValuationEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.IMAGE ? (
								<ImageTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as ImageEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.DRAWING ? (
								<DrawingTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as DrawingEntry[]} />
							) : null}
							{interaction.type === InteractionTypeT.LINK ? <LinkTableRow presentationId={presentationId} interactionId={interaction.id} entries={entries as LinkEntry[]} /> : null}
						</>
					) : (
						<Tr key={'empty'}>
							<Td></Td>
						</Tr>
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
