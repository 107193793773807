import { Link } from 'react-router';
import truncate from 'lodash/truncate';
import React from 'react';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { mapElementTypeToWords } from '../../../../util/InteractionUtils.ts';
import { translate } from '../../../../translate';

interface Props {
	interaction: VisualizationT['interaction'];
}

export const VisualizationInteractionCard: React.FC<Props> = ({ interaction }) => {
	return (
		<div className='flex flex-col my-6 bg-gray-50 gap-6 p-4 rounded-lg shadow-sm'>
			<div className='flex flex-row'>
				<p className='text-[14px] font-bold uppercase'>{translate('Interaction you are visualizing')}</p>
			</div>
			<div className='flex flex-row gap-6'>
				{/* First Column */}
				<div className='flex flex-col flex-1 items-start'>
					<div className='flex flex-row justify-start gap-2'>
						{/*
        <img
          src="/assets/brand/icons/PRESENTATION.svg"
          alt="Presentation"
          className="w-8 h-8"
        />
        */}
						<span>Presentation</span>
					</div>
					<div key={interaction.presentationId}>
						<Link to={`/presentations/${interaction.presentationId}/`}>
							<p className='font-bold text-[18px]'>{truncate(interaction.presentationTitle, { length: 100 })}</p>
						</Link>
					</div>
				</div>

				{/* Second Column */}
				<div className='flex flex-col flex-1 items-start'>
					<div className='flex flex-row justify-start gap-2'>
						{/*
        <InteractionIcon type={interaction.type} />
        */}
						<span>{mapElementTypeToWords(interaction.type)} Interaction</span>
					</div>
					<div key={interaction.id}>
						<Link to={`/presentations/${interaction.presentationId}/slides/${interaction.slideId}`}>
							<p className='font-bold text-[18px]'>{truncate(interaction.title, { length: 100 })}</p>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
