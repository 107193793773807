import React, { useEffect } from 'react';
import { BasicCardsConfigurationT, VisualizationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { saveBasicCardsVisualization } from '../../service/VisualizationService.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { Box, FormControl, FormLabel, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const BasicCardsVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: BasicCardsConfigurationT) =>
		saveBasicCardsVisualization(presentationId, visualization.id, data)
	);

	const conf = visualization.configuration as BasicCardsConfigurationT;

	const { register, handleSubmit, watch } = useForm<BasicCardsConfigurationT>({
		defaultValues: {
			maxColumns: conf.maxColumns,
			hideCardTitle: conf.hideCardTitle,
		},
	});

	const onSubmit: SubmitHandler<BasicCardsConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<VisualizationInteractionCard interaction={visualization.interaction} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={visualization.id}
								elementType={ElementTypeT.VISUALIZATION}
								title={visualization.title}
								description={visualization.description}
							/>
						</TabPanel>
						<TabPanel>
							<Box my={4}>
								<FormControl display='flex' alignItems='center'>
									<Switch size='lg' id='required' {...register('hideCardTitle')} />
									<FormLabel htmlFor='required' fontSize='lg' mx={2}>
										{translate('Hide card title')}
									</FormLabel>
								</FormControl>
							</Box>
							<Box my={16}>
								<FormControl>
									<Input
										type='number'
										size='lg'
										id='maxColumns'
										data-peer
										{...register('maxColumns', {
											min: 2,
											max: 6,
										})}
										min={2}
										max={6}
									/>
									<FormLabel htmlFor='maxColumns' variant='floating' size='lg'>
										{translate('Max columns')}
									</FormLabel>
								</FormControl>
							</Box>
							<Box>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
