import { Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { IoAddSharp } from 'react-icons/io5';
import { EmptySlide } from '../EmptySlide';
import { useLocation } from 'react-router';

interface Props {
	buttonText: string;
}

export const AddNewSlide: React.FC<Props> = ({ buttonText }) => {
	const dialogRef = useRef<HTMLDialogElement>(null);
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (isOpen) {
			closeModal();
		}
	}, [pathname]);

	const openModal = () => {
		dialogRef.current?.showModal();
		setIsOpen(true);
	};

	const closeModal = () => {
		dialogRef.current?.close();
		setIsOpen(false);
	};
	// This handler is called when the user presses Escape (or triggers a cancel event)
	const handleCancel = (e: React.SyntheticEvent) => {
		e.preventDefault(); // Prevent the default behavior if needed
		closeModal();
	};

	return (
		<>
			<Button my={1} aria-label='new slide' variant='submit' onClick={openModal} rightIcon={<IoAddSharp />}>
				{buttonText}
			</Button>
			{/* Native dialog with Tailwind styling */}
			<dialog ref={dialogRef} onCancel={handleCancel} className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70%] h-[80%] bg-white rounded-lg shadow-lg'>
				{/* Close button */}
				<button onClick={closeModal} className='absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-5xl leading-none' aria-label='Close dialog'>
					&times;
				</button>

				{/* Modal body content */}
				<div className='mt-4'>{isOpen && <EmptySlide key='empty' />}</div>
			</dialog>
			<style>{`
				dialog::backdrop {
					background: rgba(0, 0, 0, 0.6);
				}
			`}</style>
		</>
	);
};
