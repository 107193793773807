import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import classes from './IconButton.module.css';
import { useToken } from '@chakra-ui/react';
import { Loading } from '../Loading';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	icon: ReactNode;
	isLoading?: boolean;
}

export function IconButton({ icon, isLoading, className, style, onClick, ...props }: Props) {
	const [brand] = useToken('colors', ['brand']);

	const styles = {
		'--text-color': brand,
	};

	return (
		<button
			className={`
			  flex 
        justify-center
        items-center
        rounded-4xl
        text-xl
        cursor-pointer
        ${classes.button}
        ${className}
      `}
			disabled={isLoading}
			style={{ ...styles, ...style } as CSSProperties}
			onClick={(e) => {
				if (onClick && !isLoading) {
					e.preventDefault();
					onClick(e);
				}
				return e;
			}}
			{...props}
		>
			{isLoading ? <Loading /> : icon}
		</button>
	);
}
