import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '../../FirebaseAuthProvider/FirebaseAuthProvider.tsx';
import { Navigate } from 'react-router';
import { Alert, AlertIcon, Box, Button, Container, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { QuestioryLogo } from '../../../../components/QuestioryLogo';
import { translate } from '../../../../translate';

interface FormValues {
	email: string;
}

export const ForgotPasswordPage: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			email: '',
		},
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const { resetPassword, isAuthenticated } = useAuth();
	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		try {
			setIsLoading(true);
			await resetPassword(data.email);
			setSuccess(true);
		} catch (error) {
			setSuccess(false);
			setError('Error. Try again later');
		} finally {
			setIsLoading(false);
		}
	};

	if (isAuthenticated) {
		return <Navigate to='/presentations' />;
	}

	return (
		<Container maxW='lg' py={{ base: '5', md: '12' }} px={{ base: '0', sm: '8' }}>
			<Stack spacing='2'>
				<Stack spacing='2' alignItems='center'>
					<QuestioryLogo />
					<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
						<Heading size='xs'>{translate('Reset your password')}</Heading>
					</Stack>
				</Stack>
				<Box
					py={{ base: '0', sm: '8' }}
					px={{ base: '4', sm: '10' }}
					bg={{ base: 'transparent', sm: 'bg.surface' }}
					boxShadow={{ base: 'none', sm: 'md' }}
					borderRadius={{ base: 'none', sm: 'xl' }}
				>
					<Stack spacing='6' as='form' onSubmit={handleSubmit(onSubmit)}>
						<Stack spacing='5'>
							<FormControl>
								<FormLabel htmlFor='email'>Email</FormLabel>
								<Input
									id='email'
									type='email'
									autoComplete='current-email'
									{...register('email', {
										required: 'Required',
										pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Must be an email' },
									})}
								/>
								{errors.email ? <FormErrorMessage>{errors.email?.message}</FormErrorMessage> : null}
							</FormControl>
							{!isEmpty(error) ? (
								<Alert status='error'>
									<AlertIcon />
									{error}
								</Alert>
							) : null}
							{success ? (
								<Alert status='success'>
									<AlertIcon />
									{translate('Password reset requested')}
								</Alert>
							) : null}
						</Stack>
						<Stack spacing='6'>
							<Button variant='submit' type='submit' isLoading={isLoading}>
								{translate('Reset your password')}
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	);
};
