import { Flex, Heading, Icon, Input, InputGroup, InputLeftElement, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { mapElementTypeToWords } from '../../../../../../../../util/InteractionUtils.ts';
import { SearchInteractionResult } from '../../../../../../../interaction/service/InteractionService.ts';
import { useSearchInteraction } from '../../../../../../../interaction/hooks/useInteraction.ts';
import { Loading } from '../../../../../../../commons/components/Loading';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../../../../../types/Constants.ts';
import { translate } from '../../../../../../../../translate';

interface Props {
	presentationId: string;
	onSelectedInteraction: (interaction: SearchInteractionResult) => void;
}

export const InternalInteractionPicker: React.FC<Props> = ({ presentationId, onSelectedInteraction }) => {
	const [inputValue, setInputValue] = useState('');

	const { isPending, data: interactionResult } = useSearchInteraction(presentationId, inputValue);

	const handleSelectInteraction = (interaction: SearchInteractionResult) => () => {
		onSelectedInteraction(interaction);
	};

	const handleOnInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const debouncedInputChangeHandler = debounce(handleOnInputChange, AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		return () => {
			debouncedInputChangeHandler.cancel();
		};
	}, []);

	return (
		<>
			<h4 className='text-2xl text-center mt-10'>{translate('Which Interaction do you want to visualize?')}</h4>
			<Heading as='h4' size='xs' textAlign='center'></Heading>
			<>
				<Flex alignSelf='center' mb='6' width='80%'>
					<InputGroup>
						<InputLeftElement>
							<Icon as={FiSearch} />
						</InputLeftElement>
						<Input
							onChange={debouncedInputChangeHandler}
							borderBottomRadius={0}
							size='lg'
							placeholder={translate('Start typing...')}
							_hover={{ borderColor: 'light' }}
							_focus={{ borderColor: 'light' }}
						/>
					</InputGroup>
				</Flex>
				{isPending ? <Loading /> : null}
				{!isPending && isEmpty(interactionResult) ? (
					<Flex flexDirection='column' alignItems='center'>
						<Text fontSize='xl' fontWeight='medium'>
							{translate('No interactions to visualize yet. Begin by creating or using data from another Presentation')}
						</Text>
					</Flex>
				) : null}
				{!isEmpty(interactionResult) ? (
					<Flex justifyContent='center' mx={8}>
						<Table>
							<Thead _hover={{}} border='none'>
								<Tr bgColor='none'>
									<Th>Question type</Th>
									<Th>Title</Th>
								</Tr>
							</Thead>
							<Tbody>
								{interactionResult!.map((interaction) => (
									<Tr key={interaction.id} onClick={handleSelectInteraction(interaction)}>
										<Td>{mapElementTypeToWords(interaction.type)}</Td>
										<Td>
											<Flex flexDirection='row' justifyContent='flex-start' gap={4}>
												{/*<InteractionIcon type={interaction.type} />*/}
												<Text>{truncate(interaction.title, { length: 100 })}</Text>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Flex>
				) : null}
			</>
		</>
	);
};
