import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { InteractionSelector } from '../../../interaction/components/InteractionSelector';
import { IconBox } from '../../../commons/components/IconBox';
import { StaticContentSelector } from '../../../staticContent/components/StaticContentSelector';
import { VisualizationSelector } from '../../../visualization/components/VisualizationSelector';
import { translate } from '../../../../translate';

interface Props {}

export const EmptySlide: React.FC<Props> = () => {
	const [selectedElementType, setSelectedElementType] = useState<ElementTypeT | undefined>();

	const handleElementSelectorClick = (elementType: ElementTypeT) => () => {
		setSelectedElementType(elementType);
	};

	const resetSelectedElementType = () => {
		setSelectedElementType(undefined);
	};

	return (
		<Flex flexDirection='column' justifyContent='center' alignItems='center'>
			{!selectedElementType ? (
				<Flex flexDirection='column'>
					<h1 className='text-2xl text-center my-12'>{translate('What type of slide do you want to create?')}</h1>
					<Flex flexDirection={['column', null, 'row']} gap={8} alignItems='center' mx={8}>
						<IconBox
							iconImageSrc='/assets/brand/icons/ASK.svg'
							label={translate('Interaction')}
							description={translate('Inquire and get the information you need')}
							onClick={handleElementSelectorClick(ElementTypeT.INTERACTION)}
						/>
						<IconBox
							iconImageSrc='/assets/brand/icons/VIEW.svg'
							label={translate('Visualization')}
							description={translate('Bring your data to life with visualizations')}
							onClick={handleElementSelectorClick(ElementTypeT.VISUALIZATION)}
						/>
						<IconBox
							iconImageSrc='/assets/brand/icons/TELL.svg'
							label={translate('Content')}
							description={translate('Tell something to your audience')}
							onClick={handleElementSelectorClick(ElementTypeT.STATIC_CONTENT)}
						/>
					</Flex>
				</Flex>
			) : null}

			{selectedElementType === ElementTypeT.INTERACTION ? <InteractionSelector onGoBack={resetSelectedElementType} /> : null}
			{selectedElementType === ElementTypeT.VISUALIZATION ? <VisualizationSelector onGoBack={resetSelectedElementType} /> : null}
			{selectedElementType === ElementTypeT.STATIC_CONTENT ? <StaticContentSelector onGoBack={resetSelectedElementType} /> : null}
		</Flex>
	);
};
