import React, { useState } from 'react';
import { InteractionTypeT } from '../../../../types/Interaction.ts';
import { usePresentationId } from '../../../presentation/hooks/usePresentationId.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useNavigate } from 'react-router';
import { Loading } from '../../../commons/components/Loading';
import { NewInteraction } from './_/NewInteraction';
import { NewInteractionValues } from './_/NewInteraction/NewInteraction.tsx';
import { translate } from '../../../../translate';
import { createInteraction } from '../../../element/service/ElementService.ts';

interface Props {
	onGoBack: () => void;
}

const allInteractions: InteractionTypeT[] = [
	InteractionTypeT.OPEN_ENDED,
	InteractionTypeT.OPEN_ENDED_LONG,
	InteractionTypeT.CATEGORISED,
	InteractionTypeT.VALUATION,
	InteractionTypeT.MULTIPLE_CHOICE,
	InteractionTypeT.DRAWING,
	InteractionTypeT.ICONIC,
	InteractionTypeT.IMAGE,
	InteractionTypeT.NUMBER,
	InteractionTypeT.LINK,
];

export const InteractionSelector: React.FC<Props> = ({ onGoBack }) => {
	const presentationId = usePresentationId();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnSubmit = async (values: NewInteractionValues) => {
		setIsLoading(true);
		const newSlide = await createInteraction(presentationId, {
			title: values.title,
			type: values.type,
		});
		invalidatePresentationQuery();
		navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
	};

	if (isLoading) {
		return <Loading size='md' />;
	}

	return (
		<NewInteraction
			title={translate('What type of interaction do you want to create?')}
			supportedInteractions={allInteractions}
			onSelectedInteraction={handleOnSubmit}
			onGoBack={onGoBack}
		/>
	);
};
