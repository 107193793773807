import { forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { INPUT_TITLE_MAX_LENGHT, INPUT_TITLE_MIN_LENGHT } from '../../../../types/Constants.ts';
import { translate } from '../../../../translate';
import { QuestioryInput } from '../Input';

interface Props {
	title: string;
	placeholder?: string;
}

export type Ref = HTMLInputElement;

export const InputTitle = forwardRef<Ref, Props>(({ title, ...props }, ref) => {
	return (
		<QuestioryInput
			id='title'
			error={isEmpty(title)}
			className='text-xl'
			label={translate('Title')}
			maxLength={INPUT_TITLE_MAX_LENGHT}
			minLength={INPUT_TITLE_MIN_LENGHT}
			required
			{...props}
			ref={ref}
		/>
	);
});

InputTitle.displayName = 'InputTitle';
