import { ComponentStyleConfig, extendTheme, theme as baseTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import { switchTheme } from './switch';
import { modalTheme } from './modal';
import { tagTheme } from './tag';

export const AUTHOR_DEFAULT_FONT = 'Montserrat';

const headingTheme: ComponentStyleConfig = {
	baseStyle: {
		my: '12',
	},
	defaultProps: {
		size: ['sm', null, 'md'],
	},
};

const buttonTheme: ComponentStyleConfig = {
	baseStyle: {
		bgColor: 'brand',
		h: '3.75rem',
		minW: '3.75rem',
		fontSize: 'xl',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		px: 7,
		my: 8,
		color: 'white',
		borderRadius: 'full',
	},
	defaultProps: {
		colorScheme: '',
		size: 'lg',
	},
};

const tableTheme: ComponentStyleConfig = {
	variants: {
		simple: {
			th: {
				background: 'white',
				borderTop: 'none',
			},
			tr: {
				_hover: { cursor: 'pointer', bg: 'hover' },
			},
		},
	},
	defaultProps: {
		variant: 'simple',
	},
};

const menuTheme: ComponentStyleConfig = {
	variants: {
		simple: {
			item: {
				_hover: {
					bg: 'hover',
				},
				_active: {
					bg: 'hover',
				},
				_focus: {
					bg: 'hover',
				},
			},
		},
	},
};

const inputTheme: ComponentStyleConfig = {
	defaultProps: {
		variant: 'flushed',
		focusBorderColor: 'brand',
	},
};

const customTheme = {
	styles: {
		global: {
			// styles for the `body`
			html: {},
			body: {
				bg: 'background',
				scrollBehavior: 'smooth',
				overflow: 'hidden',
				'::-webkit-scrollbar': {
					bg: 'blue',
					width: '1em',
				},
				'::-webkit-scrollbar-track': {
					bg: 'blue',
					boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
				},
				'::-webkit-scrollbar-thumb': {
					bg: 'blue',
				},
				'::-webkit-scrollbar-thumb:hover': {
					background: 'red',
				},
			},
			// styles for the `a`
			a: {
				color: 'brand',
				_hover: {
					textDecoration: 'underline',
				},
			},
		},
	},
	colors: {
		brand: '#202831',
		selectedColor: '#b0b0b0',
		fontColor: 'white',
		colorScheme: {
			50: '#fafafa',
			100: '#F3F5F7',
			200: '#E6EAEF',
			300: '#CED6DF',
			400: '#B5C1CF',
			500: '#9CACBF',
			600: '#8497AE',
			700: '#6B829E',
			800: '#596E88',
			900: '#303C4A',
		},
		light: '#dcedff',
		background: '#f7fbfb',
		success: baseTheme.colors.green,
	},
	components: {
		Heading: headingTheme,
		Button: buttonTheme,
		Table: tableTheme,
		Menu: menuTheme,
		Input: inputTheme,
		Switch: switchTheme,
		Tag: tagTheme,
		Modal: modalTheme,
	},
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
	fonts: {
		heading: AUTHOR_DEFAULT_FONT,
		body: AUTHOR_DEFAULT_FONT,
	},
};

const extended = extendTheme(proTheme, { ...customTheme });

// console.log({ theme: extended });
export default extended;
