import { useState } from 'react';
import { usePresentationId } from '../../../presentation/hooks/usePresentationId.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useNavigate } from 'react-router';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';
import { translate } from '../../../../translate';
import { Flex } from '@chakra-ui/react';
import { NewElementHeader } from '../../../commons/components/NewElementHeader';
import isEmpty from 'lodash/isEmpty';
import { NewStaticContentInput, NewStaticContentInputFormValues } from './NewStaticContentInput.tsx';
import { createStaticContent } from '../../../element/service/ElementService.ts';
import { Card } from '../../../commons/components/Card';
import { getIconForElement, mapElementTypeToWords } from '../../../../util/InteractionUtils.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';

interface Props {
	onGoBack: () => void;
}

const supportedTypes = [
	StaticContentTypeT.COVER,
	StaticContentTypeT.CALL_TO_ACTION,
	StaticContentTypeT.STATEMENT,
	StaticContentTypeT.QUOTE,
	StaticContentTypeT.LIST,
	StaticContentTypeT.EMBED,
];

export function StaticContentSelector({ onGoBack }: Props) {
	const presentationId = usePresentationId();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [selectedContentType, setSelectedContentType] = useState<StaticContentTypeT>();
	const [newContentInput, setNewContentInput] = useState<NewStaticContentInputFormValues>();

	const handleOnGoBack = () => {
		setSelectedContentType(undefined);
	};

	const onSubmit = async (values: NewStaticContentInputFormValues) => {
		if (!isLoading && selectedContentType) {
			setIsLoading(true);
			setNewContentInput(values);
			const newSlide = await createStaticContent(presentationId, {
				title: values.title,
				type: selectedContentType,
			});
			await invalidatePresentationQuery();
			navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
		}
	};

	return (
		<>
			{!selectedContentType ? (
				<Flex flexDirection='column' alignItems='center'>
					<NewElementHeader title={translate('Which type of content slide do you want to create?')} onGoBack={onGoBack} />
					<div className='flex justify-center items-center'>
						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8'>
							{supportedTypes.map((type) => (
								<Card key={type} className='flex gap-4 justify-start items-center hover:cursor-pointer' onClick={() => setSelectedContentType(type)}>
									<img className='h-20 w-20' src={getIconForElement(ElementTypeT.STATIC_CONTENT)(type)} alt='interaction' />
									<span className='text-xl'>{mapElementTypeToWords(type)}</span>
								</Card>
							))}
						</div>
					</div>
				</Flex>
			) : null}
			{selectedContentType && isEmpty(newContentInput) ? <NewStaticContentInput onSubmit={onSubmit} onGoBack={handleOnGoBack} /> : null}
		</>
	);
}
