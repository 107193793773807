import React, { useEffect, useRef } from 'react';
import { Chrome, ColorResult } from '@uiw/react-color';
import { useDisclosure, useOutsideClick } from '@chakra-ui/react';

enum ChromeInputType {
	HEXA = 'hexa',
}

interface Props {
	value: string;
	onChange: (colorHex: string) => void;
}

/**
 * Input adaptor for a color picker
 *
 * https://uiwjs.github.io/react-color/
 */
export const ColorInput: React.FC<Props> = ({ value, onChange }) => {
	const { isOpen, onToggle, onClose } = useDisclosure({ defaultIsOpen: false });
	const wrapperRef = useRef<HTMLDivElement>(null);

	useOutsideClick({
		ref: wrapperRef,
		handler: () => {
			onClose();
		},
	});

	const handleOnChange = (color: ColorResult) => {
		onChange(color.hex);
	};

	// Add useEffect to handle Escape key press
	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		// Add event listener
		document.addEventListener('keydown', handleEscape);

		// Cleanup
		return () => {
			document.removeEventListener('keydown', handleEscape);
		};
	}, [onClose]); // Ensuring effect runs only if onClose changes

	return (
		<div>
			<div
				className='w-10 h-10 mr-2 border rounded-full hover:cursor-pointer'
				style={{
					backgroundColor: value,
					color: value,
					borderColor: 'black',
				}}
				onClick={onToggle}
			/>
			{isOpen ? (
				<div id='color-picker' className='absolute z-10' ref={wrapperRef}>
					<Chrome onChange={handleOnChange} color={value} inputType={ChromeInputType.HEXA} />
				</div>
			) : null}
		</div>
	);
};
