import React from 'react';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { IconButton } from '../IconButton';

interface Props {
	title: string;
	onGoBack: () => void;
}

export const NewElementHeader: React.FC<Props> = ({ title, onGoBack }) => {
	return (
		<div className='flex justify-center items-center my-10 gap-4'>
			<IconButton aria-label='' icon={<MdOutlineArrowBackIos />} onClick={onGoBack} />
			{/*<h2 className='text-3xl text-center font-bold'>{title}</h2>*/}
			<h2 className='text-2xl text-center'>{title}</h2>
		</div>
	);
};
