import React, { useState } from 'react';
import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	Link,
	Link as ChakraLink,
	Stack,
	Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, Navigate } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '../../FirebaseAuthProvider/FirebaseAuthProvider.tsx';
import isEmpty from 'lodash/isEmpty';
import { FiExternalLink } from 'react-icons/fi';
import { getLanguage, translate } from '../../../../translate';
import { QuestioryLogo } from '../../../../components/QuestioryLogo';

interface FormValues {
	email: string;
	password: string;
}

export const LoginPage: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			email: '',
			password: '',
		},
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const { login, isAuthenticated } = useAuth();
	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		try {
			setIsLoading(true);
			await login(data.email, data.password);
		} catch (error) {
			setError(translate('Invalid credentials'));
		} finally {
			setIsLoading(false);
		}
	};

	if (isAuthenticated) {
		return <Navigate to='/presentations' />;
	}

	return (
		<Container maxW='lg' py={{ base: '5', md: '12' }} px={{ base: '0', sm: '8' }}>
			<Stack spacing='12'>
				<Stack spacing='2' alignItems='center'>
					<Link href='https://questiory.com' target='_self' title='Go to Questiory'>
						<QuestioryLogo />
					</Link>
					<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
						<Heading size='xs' mb={2}>
							{translate('Log in to your account')}
						</Heading>
						<HStack>
							<Text>{translate('Dont have an account?')}</Text>
							<Link href={getLanguage() === 'en' ? 'https://questiory.com/#joinBeta' : 'https://questiory.com/es/#joinBeta'} isExternal>
								<u>{translate('Request access here')}</u>
							</Link>
						</HStack>
					</Stack>
				</Stack>
				<Box
					py={{ base: '0', sm: '8' }}
					px={{ base: '4', sm: '10' }}
					bg={{ base: 'transparent', sm: 'bg.surface' }}
					boxShadow={{ base: 'none', sm: 'md' }}
					borderRadius={{ base: 'none', sm: 'xl' }}
				>
					<Stack spacing='6' as='form' onSubmit={handleSubmit(onSubmit)}>
						<Stack spacing='5'>
							<FormControl>
								<FormLabel htmlFor='email'>Email</FormLabel>
								<Input
									id='email'
									type='email'
									autoComplete='current-email'
									{...register('email', {
										required: 'Required',
										pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Must be an email' },
									})}
								/>
								{errors.email ? <FormErrorMessage>{errors.email?.message}</FormErrorMessage> : null}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor='password'>{translate('Password')}</FormLabel>
								<Input
									id='password'
									type='password'
									autoComplete='current-password'
									{...register('password', {
										required: 'Required',
										minLength: {
											value: 1,
											message: 'Required',
										},
									})}
								/>
								{errors.password ? <FormErrorMessage>{errors.password?.message}</FormErrorMessage> : null}
							</FormControl>
							{!isEmpty(error) ? (
								<Alert status='error'>
									<AlertIcon />
									{error}
								</Alert>
							) : null}
						</Stack>
						<HStack justifyContent='flex-end'>
							<ChakraLink as={ReactRouterLink} to='/forgot-password'>
								{translate('Forgot password?')}
							</ChakraLink>
						</HStack>
						<Stack spacing='6'>
							<Button variant='submit' type='submit' isLoading={isLoading}>
								{translate('Sign in')}
							</Button>
						</Stack>
					</Stack>
					<Text>
						{translate('By using Questiory you agree to abide by our')}
						<Flex alignItems='center' gap={1}>
							<Link href='https://questiory.com/terms-of-service/' isExternal>
								{translate('Terms of service')}
							</Link>
							<Link href='https://questiory.com/terms-of-service/' isExternal>
								<FiExternalLink />
							</Link>
						</Flex>
					</Text>
				</Box>
			</Stack>
		</Container>
	);
};
