import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_TITLE_MAX_LENGHT, INPUT_TITLE_MIN_LENGHT } from '../../../../types/Constants.ts';
import { QuestioryInput } from '../Input';
import isEmpty from 'lodash/isEmpty';

interface Props {
	id: string;
	currentValue: string;
	maxLength: number;
	onSave: (value: string) => void;
}

interface FormValue {
	value: string;
}

export const AutoSaveInput: React.FC<Props> = ({ id, currentValue, maxLength, onSave }) => {
	const { register, handleSubmit, watch } = useForm<FormValue>({
		defaultValues: {
			value: currentValue,
		},
	});

	const debouncedSave = useMemo(
		() =>
			debounce(async (data: FormValue) => {
				return onSave(data.value);
			}, AUTO_SAVE_DEBOUNCE_TIME),
		[onSave]
	);

	const onSubmit: SubmitHandler<FormValue> = useCallback(
		async (data) => {
			await debouncedSave(data);
		},
		[debouncedSave]
	);

	useEffect(() => {
		const subscription = watch(() => {
			handleSubmit(onSubmit)();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, handleSubmit, onSubmit]);

	return (
		<QuestioryInput
			id={id}
			placeholder='Title'
			className={`placeholder:text-2xl text-2xl ${!isEmpty(watch('value')) && 'border-none'}`}
			minLength={INPUT_TITLE_MIN_LENGHT}
			maxLength={INPUT_TITLE_MAX_LENGHT}
			required
			{...register('value', {
				required: true,
				minLength: 1,
				maxLength: maxLength,
			})}
		/>
	);
};
