import truncate from 'lodash/truncate';
import React, { useEffect } from 'react';
import { BasicSlideT } from '../../../../../../types/Presentation.ts';
import { mapElementTypeToWords } from '../../../../../../util/InteractionUtils.ts';
import { motion } from 'framer-motion';

interface Props {
	slide: BasicSlideT;
	isSelected: boolean;
	onSelectSlide: (slide: BasicSlideT) => void;
}

export const SlideBox: React.FC<Props> = ({ slide, isSelected, onSelectSlide }) => {
	const handleOnSelectedSlide = () => {
		onSelectSlide(slide);
	};

	const slideId = `slide-${String(slide.id)}`;

	useEffect(() => {
		// document.getElementById(slideId)?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
	}, [slideId, isSelected]);

	return (
		<motion.div
			id={slideId}
			className={`
				flex flex-col font-bold justify-around text-center items-center h-40 min-h-40 w-38 min-w-38 max-w-38 my-3 rounded-lg px-1
				${isSelected && 'border-2 border-black'}
				${isSelected ? 'bg-white' : 'bg-[#F3F5F7]'} 
				`}
			onClick={handleOnSelectedSlide}
			whileTap={{ scale: 1 }}
			title={slide.title}
		>
			{isSelected ? (
				<div className='flex flex-col'>
					<div className='flex h-[4.2rem] overflow-y-auto' style={{ scrollbarColor: 'transparent transparent' }}>
						<p className='text-center m-auto no-underline'>{slide.title}</p>
					</div>
					<hr className='my-2 border-t border-dashed' />
					<p className='text-[12px] uppercase'>{mapElementTypeToWords(slide.element.type)}</p>
				</div>
			) : (
				<p className='text-center no-underline'>{truncate(slide.title, { length: 40 })}</p>
			)}
		</motion.div>
	);
};
