import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Grid, GridItem, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { IconicInteractionConfigurationT, InteractionT } from '../../../../types/Interaction.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { saveIconicInteraction } from '../../service/InteractionService.ts';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { AUTO_SAVE_DEBOUNCE_TIME, PLACE_HOLDER_MAX_LENGTH } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const IconicInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateOpenEndedInteraction = useUpdateInteraction(presentationId, interaction.id, (data: IconicInteractionConfigurationT) =>
		saveIconicInteraction(presentationId, interaction.id, data)
	);

	const conf = interaction.configuration as IconicInteractionConfigurationT;

	const { register, handleSubmit, watch } = useForm<IconicInteractionConfigurationT>({
		defaultValues: {
			placeholderTitle: conf.placeholderTitle,
			placeholderDescription: conf.placeholderDescription,
			longAnswer: conf.longAnswer,
			required: interaction.required,
		},
	});

	const onSubmit: SubmitHandler<IconicInteractionConfigurationT> = async (data) => {
		updateOpenEndedInteraction.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={interaction.id}
								elementType={ElementTypeT.INTERACTION}
								title={interaction.title}
								description={interaction.description}
							/>
							<Flex flexDirection='column' mt={8} gap={14}>
								<FormControl>
									<Input
										id='placeholderTitle'
										fontSize={18}
										fontWeight='bold'
										size='lg'
										data-peer
										maxLength={PLACE_HOLDER_MAX_LENGTH}
										{...register('placeholderTitle', {
											maxLength: PLACE_HOLDER_MAX_LENGTH,
										})}
									/>
									<FormLabel htmlFor='placeholderTitle' variant='floating' size='lg'>
										Title placeholder
									</FormLabel>
								</FormControl>
								<FormControl display={watch('longAnswer') ? undefined : 'none'}>
									<Input
										id='placeholderDescription'
										fontSize={18}
										fontWeight='bold'
										size='lg'
										data-peer
										maxLength={PLACE_HOLDER_MAX_LENGTH}
										{...register('placeholderDescription', {
											maxLength: PLACE_HOLDER_MAX_LENGTH,
										})}
									/>
									<FormLabel htmlFor='placeholderDescription' variant='floating' size='lg'>
										Description placeholder
									</FormLabel>
								</FormControl>
							</Flex>
						</TabPanel>
						<TabPanel>
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<Flex flexDirection='column'>
								<Grid mt={5} templateColumns='repeat(1, 1fr)' gap={4} alignItems='center'>
									<GridItem colSpan={1}>
										<Flex flexDirection='row' alignItems='center'>
											<Switch size='lg' id='longAnswer' {...register('longAnswer')} />
											<FormLabel htmlFor='longanswer' fontSize='lg' mx={2}>
												{translate('Long answers')}
											</FormLabel>
										</Flex>
									</GridItem>
								</Grid>
							</Flex>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
