import { Button, Collapse } from '@chakra-ui/react';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_DESCRIPTION_MAX_LENGHT, INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { BasicPresentationT, ElementTypeT } from '../../../../types/Presentation.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateElement, UpdateElementInput } from '../../service/ElementService.ts';
import { InputTitle } from '../../../commons/components/InputTitle';
import { refreshElementPreview } from '../../../presentation/components/ElementPreview/ElementPreview.tsx';
import { translate } from '../../../../translate';
import isEmpty from 'lodash/isEmpty';
import { trim } from 'lodash';
import { interactionKey } from '../../../interaction/hooks/useInteraction.ts';
import { staticConentkey } from '../../../staticContent/hooks/useStaticContent.ts';
import { visualizationKey } from '../../../visualization/hooks/useVisualization.ts';
import { QuestioryInput } from '../../../commons/components/Input';

interface Props {
	presentationId: BasicPresentationT['id'];
	elementId: string;
	elementType: ElementTypeT;
	title: string;
	titlePlaceholder?: string;
	description?: string;
}

interface TitleFormT {
	title: string;
	description?: string;
}

const keyGenerators = {
	[ElementTypeT.INTERACTION]: interactionKey,
	[ElementTypeT.STATIC_CONTENT]: staticConentkey,
	[ElementTypeT.VISUALIZATION]: visualizationKey,
};

export function TitleForm({ presentationId, elementId, elementType, title, titlePlaceholder, description }: Props) {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const [shouldShowDescription, setShouldShowDescription] = useState(!isEmpty(description));
	const { invalidateQueries } = useQueryClient();

	const updateElementMutation = useMutation({
		mutationFn: (input: UpdateElementInput) => {
			return updateElement(presentationId, elementId, {
				title: trim(input.title),
				description: trim(input.description),
				elementType: input.elementType,
			});
		},
		onSuccess: async () => {
			refreshElementPreview();
			const keyGenerator = keyGenerators[elementType];
			if (keyGenerator) {
				await invalidateQueries({ queryKey: keyGenerator(presentationId, elementId) });
			} else {
				throw new Error(`Unknown elementType: ${elementType}`);
			}
		},
	});
	const { register, handleSubmit, watch, setValue } = useForm<TitleFormT>({
		defaultValues: {
			title,
			description,
		},
		shouldFocusError: false,
	});

	useEffect(() => {
		setValue('title', title);
		setValue('description', description);
	}, [title, description]);

	const handleOnSubmit: SubmitHandler<TitleFormT> = async (data) => {
		updateElementMutation.mutate({
			title: data.title,
			description: data.description,
			elementType: elementType,
		});
		setShouldShowDescription(!isEmpty(data.description));
	};

	const debouncedSubmit = debounce(handleSubmit(handleOnSubmit), AUTO_SAVE_DEBOUNCE_TIME);
	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<div className='flex flex-col gap-4 mt-10'>
			<div>
				<InputTitle
					title={watch('title')}
					placeholder={titlePlaceholder}
					{...register('title', {
						maxLength: INPUT_TITLE_MAX_LENGHT,
						required: true,
					})}
				/>
			</div>
			<div className='flex flex-col'>
				<div>
					{!shouldShowDescription && (
						<Button
							variant='outline'
							type='button'
							onClick={(e) => {
								e.stopPropagation();
								setShouldShowDescription(true);
							}}
							size='sm'
							fontSize='xs'
							mt={4}
						>
							{translate('Add a description')}
						</Button>
					)}

					<Collapse in={shouldShowDescription}>
						<QuestioryInput
							id='description'
							// label={translate('Description')}
							placeholder={translate('Description')}
							maxLength={INPUT_DESCRIPTION_MAX_LENGHT}
							{...register('description', {
								maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
							})}
						/>
					</Collapse>
				</div>
			</div>
		</div>
	);
}
