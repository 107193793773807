import React, { useState } from 'react';
import { SearchInteractionResult } from '../../../../../interaction/service/InteractionService.ts';
import { InteractionPicker } from './_/InteractionPicker';
import { VisualizationPicker } from './_/VisualizationPicker';
import { usePresentationId } from '../../../../../presentation/hooks/usePresentationId.ts';

interface Props {
	onGoBack: () => void;
}

export const ExistingDataVisualization: React.FC<Props> = ({ onGoBack }) => {
	const presentationId = usePresentationId();
	const [selectedInteraction, setSelectedInteraction] = useState<SearchInteractionResult>();

	const handleSelectInteraction = (interaction: SearchInteractionResult) => {
		setSelectedInteraction(interaction);
	};

	const handleVisualizationBackButton = () => {
		setSelectedInteraction(undefined);
	};

	return (
		<div className='flex flex-col w-full'>
			{!selectedInteraction ? (
				<InteractionPicker presentationId={presentationId} onSelectedInteraction={handleSelectInteraction} onGoBack={onGoBack} />
			) : (
				<VisualizationPicker presentationId={presentationId} interaction={selectedInteraction} onGoBack={handleVisualizationBackButton} />
			)}
		</div>
	);
};
