import React from 'react';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { InteractionT } from '../../../../types/Interaction.ts';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { StaticContentT } from '../../../../types/StaticContent.ts';
import { ElementMoreMenuV2 } from '../ElementMoreMenu';
import { mapElementTypeToWords } from '../../../../util/InteractionUtils.ts';

interface Props {
	presentationId: string;
	elementType: ElementTypeT;
	element: InteractionT | VisualizationT | StaticContentT;
}

export const ElementConfigurationTopBar: React.FC<Props> = ({ presentationId, elementType, element }) => {
	return (
		<>
			<div id='element-topbar' className='flex flex-row justify-between items-start'>
				<div className='flex'>
					<span className='mb-2 inline-flex items-center py-2 px-4 rounded-full bg-gray-100 text-brand'>
						<span className='text-xl'>{mapElementTypeToWords(element.type)}</span>
					</span>
				</div>
				<div>
					<ElementMoreMenuV2 element={element} elementType={elementType} presentationId={presentationId} />
				</div>
			</div>
		</>
	);
};
