import { forwardRef, InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	error?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(({ label, className, error, style, ...props }, ref) => {
	return (
		<div id={props.id} className='w-full'>
			<input
				ref={ref}
				style={{ ...style }}
				className={`
					w-full h-[2.5rem] font-bold bg-transparent border-b focus:outline-none
				 placeholder:text-gray-500 placeholder:font-normal placeholder:text-md
				  ${error ? 'focus:border-red-500' : 'focus:border-black'}
					${error ? 'border-red-500' : 'border-gray-300'} ${className}`}
				{...props}
			/>
			<label htmlFor={props.id} className='text-gray-700 relative text-start text-sm top-[-4.25rem]'>
				{label}
			</label>
		</div>
	);
});

Input.displayName = 'Input';

export { Input };
