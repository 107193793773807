import React, { useEffect } from 'react';
import { InteractionT, LinkInteractionConfigurationT } from '../../../../types/Interaction.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { saveLinkInteraction } from '../../service/InteractionService.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME, PLACE_HOLDER_MAX_LENGTH } from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { Box, Flex, FormControl, FormLabel, Grid, GridItem, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const LinkInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const conf = interaction.configuration as LinkInteractionConfigurationT;
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const updateInteraction = useUpdateInteraction(presentationId, interaction.id, (data: LinkInteractionConfigurationT) =>
		saveLinkInteraction(presentationId, interaction.id, data)
	);

	const { register, handleSubmit, watch } = useForm<LinkInteractionConfigurationT>({
		defaultValues: {
			openInNewTab: conf.openInNewTab,
			placeholder: conf.placeholder,
			required: interaction.required,
		},
	});

	const onSubmit: SubmitHandler<LinkInteractionConfigurationT> = async (data) => {
		updateInteraction.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={interaction.id}
								elementType={ElementTypeT.INTERACTION}
								title={interaction.title}
								description={interaction.description}
							/>
							<FormControl mt={8}>
								<Input
									id='placeholder'
									fontSize={18}
									fontWeight='bold'
									size='lg'
									data-peer
									maxLength={PLACE_HOLDER_MAX_LENGTH}
									{...register('placeholder', {
										maxLength: PLACE_HOLDER_MAX_LENGTH,
									})}
								/>
								<FormLabel htmlFor='placeholder' variant='floating' size='lg'>
									Placeholder
								</FormLabel>
							</FormControl>
						</TabPanel>
						<TabPanel>
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<Flex flexDirection='column'>
								<Grid mt={5} templateColumns='repeat(1, 1fr)' gap={4} alignItems='center'>
									<GridItem colSpan={1}>
										<Flex flexDirection='row' alignItems='center'>
											<Switch size='lg' id='openInNewTab' {...register('openInNewTab')} />
											<FormLabel htmlFor='openInNewTab' fontSize='lg' mx={2}>
												Open in new Tab
											</FormLabel>
										</Flex>
									</GridItem>
								</Grid>
							</Flex>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
