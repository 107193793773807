import React, { ChangeEvent, useState } from 'react';
import { InternalInteractionPicker } from '../InternalInteractionPicker';
import { SearchInteractionResult } from '../../../../../../../interaction/service/InteractionService.ts';
import { usePresentationList } from '../../../../../../../presentation/hooks/usePresentationList.ts';
import { Loading } from '../../../../../../../commons/components/Loading';
import { Flex, Heading, Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../../../../../types/Constants.ts';
import { PresentationTable } from './PresentationTable.tsx';
import { translate } from '../../../../../../../../translate';
import { PresentationListT } from '../../../../../../../../types/Presentation.ts';
import { TagLabel } from '../../../../../../../commons/components/TagLabel';

export interface SelectedInteractionProps {
	presentation: PresentationListT;
	interaction: SearchInteractionResult;
}

interface Props {
	currentPresentationId: string;
	onSelectedInteraction: (selection: SelectedInteractionProps) => void;
}

export const ExternalInteractionPicker: React.FC<Props> = ({ currentPresentationId, onSelectedInteraction }) => {
	const [inputValue, setInputValue] = useState('');
	const [currentPage, setCurrentPage] = useState(new Date());
	const { isLoading, data } = usePresentationList(inputValue, currentPage);
	const [selectedPresentation, setSelectedPresentation] = useState<PresentationListT>();

	const handleOnPresentationClick = (presentation: PresentationListT) => {
		setSelectedPresentation(presentation);
	};

	const handleOnSelectedInteraction = (interaction: SearchInteractionResult) => {
		if (selectedPresentation && interaction) {
			onSelectedInteraction({ presentation: selectedPresentation, interaction });
			setSelectedPresentation(undefined);
		}
	};

	const handleOnInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
		setCurrentPage(new Date());
	};

	const debouncedInputChangeHandler = debounce(handleOnInputChange, AUTO_SAVE_DEBOUNCE_TIME);

	if (!selectedPresentation) {
		const filteredData = data?.filter((value) => value.id !== currentPresentationId) || [];
		return (
			<>
				<Heading as='h4' size='xs' textAlign='center' mt={8}>
					{translate('Select presentation')}
				</Heading>
				<Flex alignSelf='center' mb='6' width='80%'>
					<InputGroup>
						<InputLeftElement>
							<Icon as={FiSearch} />
						</InputLeftElement>
						<Input
							id='Presentation/Search'
							onChange={debouncedInputChangeHandler}
							borderBottomRadius={0}
							size='lg'
							placeholder={translate('Start typing...')}
							_hover={{ borderColor: 'light' }}
							_focus={{ borderColor: 'light' }}
						/>
					</InputGroup>
				</Flex>
				{isLoading ? <Loading /> : null}
				{!isLoading ? <PresentationTable presentations={filteredData} onPresentationClick={handleOnPresentationClick} /> : null}
			</>
		);
	} else {
		return (
			<>
				<Flex gap={2} justifyContent='center'>
					<h5 className='text-lg font-bold mb-0 mt-8'>
						{translate('Presentation')}:{' '}
						<TagLabel>
							<span>{selectedPresentation.title}</span>
						</TagLabel>
					</h5>
					{/*<Heading as='h5' size='xs' mb={0} mt={8}>*/}
					{/*	{translate('Presentation')}: <Tag size='lg'>{selectedPresentation.title}</Tag>*/}
					{/*</Heading>*/}
				</Flex>
				<InternalInteractionPicker presentationId={selectedPresentation.id} onSelectedInteraction={handleOnSelectedInteraction} />
			</>
		);
	}
};
