import React, { useEffect } from 'react';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { InteractionT } from '../../../../types/Interaction.ts';
import { Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { useDeleteAllEntries } from '../../hooks/useDeleteAllEntries.ts';
import { translate } from '../../../../translate';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	onAfterDeletion: () => void;
}

export const DeleteInteractionEntries: React.FC<Props> = ({ presentationId, interactionId, onAfterDeletion }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const mutation = useDeleteAllEntries(presentationId, interactionId);

	const handleOnDeleteEntries = async () => {
		mutation.mutate();
	};

	useEffect(() => {
		if (mutation.isSuccess) {
			onAfterDeletion();
			onClose();
		}
	}, [mutation.isSuccess, onAfterDeletion]);

	return (
		<>
			<Button variant='solid' leftIcon={<MdClose />} onClick={onOpen}>
				{translate('Delete all')}
			</Button>
			<Modal isOpen={isOpen} size='xl' isCentered onClose={onClose}>
				<ModalOverlay />
				<ModalContent p={8}>
					<ModalCloseButton />
					<ModalHeader>
						<Text fontSize='2xl' textAlign='center'>
							{translate('This will permanently delete all data captured for the Interaction')}
						</Text>
					</ModalHeader>
					<ModalBody m={0} p={0} textAlign='center' as={Flex} justifyContent='center' alignItems='center'>
						<Heading m={0} pt={0} fontSize='md'>
							{translate('Are you sure? This action cannot be reversed')}
						</Heading>
					</ModalBody>
					<ModalFooter my={2} justifyContent='space-around'>
						<Button onClick={handleOnDeleteEntries} variant='submit' isDisabled={mutation.isPending} isLoading={mutation.isPending}>
							{translate('Yes, delete')}
						</Button>
						<Button onClick={onClose} variant='submit'>
							{translate('No')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
