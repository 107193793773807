import React from 'react';
import { InteractionTypeT } from '../../../../types/Interaction.ts';
import { BasicElementT } from '../../../../types/Presentation.ts';
import { NumberInteraction } from '../NumberInteraction';
import { OpenEndedAnswerInteraction } from '../OpenEndedAnswerInteraction';
import { IconicInteraction } from '../IconicInteraction';
import { MultipleChoiceInteraction } from '../MultipleChoiceInteraction';
import { CategorisedAnswerInteraction } from '../CategorisedAnswerInteraction';
import { useInteraction } from '../../hooks/useInteraction.ts';
import { ValuationInteraction } from '../ValuationInteraction';
import { DrawingInteraction } from '../DrawingInteraction';
import { ImageInteraction } from '../ImageInteraction';
import { Loading } from '../../../commons/components/Loading';
import { LinkInteraction } from '../LinkInteraction';
import { OpenEndedLongAnswerInteraction } from '../OpenEndedLongAnswerInteraction';

interface Props {
	presentationId: string;
	element: BasicElementT;
}

export const Interaction: React.FC<Props> = ({ presentationId, element }) => {
	const configurationType = element.type as InteractionTypeT;
	const { isLoading, data } = useInteraction(presentationId, element.id);

	if (isLoading && !data) {
		return <Loading size='md' center />;
	}

	if (data && configurationType === InteractionTypeT.MULTIPLE_CHOICE) {
		return <MultipleChoiceInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.OPEN_ENDED) {
		return <OpenEndedAnswerInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.OPEN_ENDED_LONG) {
		return <OpenEndedLongAnswerInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.CATEGORISED) {
		return <CategorisedAnswerInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.VALUATION) {
		return <ValuationInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.ICONIC) {
		return <IconicInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.DRAWING) {
		return <DrawingInteraction presentationId={presentationId} interaction={data} key={data.id} />;
	} else if (data && configurationType === InteractionTypeT.NUMBER) {
		return <NumberInteraction key={data.id} presentationId={presentationId} interaction={data} />;
	} else if (data && configurationType === InteractionTypeT.IMAGE) {
		return <ImageInteraction key={data.id} presentationId={presentationId} interaction={data} />;
	} else if (data && configurationType === InteractionTypeT.LINK) {
		return <LinkInteraction key={data.id} presentationId={presentationId} interaction={data} />;
	}

	return null;
};
