import React, { useState } from 'react';
import { InteractionTypeT } from '../../../../../../types/Interaction.ts';
import { Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { NewInteractionInput } from './_/NewInteractionInput';
import { NewElementHeader } from '../../../../../commons/components/NewElementHeader';
import { getIconForElement, mapElementTypeToWords } from '../../../../../../util/InteractionUtils.ts';
import { ElementTypeT } from '../../../../../../types/Presentation.ts';
import { Card } from '../../../../../commons/components/Card';

export interface NewInteractionValues {
	title: string;
	type: InteractionTypeT;
}

interface Props {
	title: string;
	supportedInteractions: InteractionTypeT[];
	onSelectedInteraction: (newInteraction: NewInteractionValues) => void;
	onGoBack: () => void;
}

export const NewInteraction: React.FC<Props> = ({ title, supportedInteractions, onSelectedInteraction, onGoBack }) => {
	const [selectedInteractionType, setSelectedInteractionType] = useState<InteractionTypeT | undefined>();
	const [newInteractionTitle, setNewInteractionTitle] = useState('');

	const handleOnGoBack = () => {
		setSelectedInteractionType(undefined);
		setNewInteractionTitle('');
	};

	const handleSubmit = (interactionTitle: string) => {
		setNewInteractionTitle(interactionTitle);

		if (selectedInteractionType && !isEmpty(interactionTitle)) {
			onSelectedInteraction({
				title: interactionTitle,
				type: selectedInteractionType,
			});
		}
	};

	return (
		<>
			{!selectedInteractionType ? (
				<Flex flexDirection='column'>
					<NewElementHeader title={title} onGoBack={onGoBack} />
					<div className='flex justify-center items-center'>
						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8'>
							{supportedInteractions.map((interaction) => (
								<Card key={interaction} className='flex gap-4 justify-start items-center hover:cursor-pointer min-w-65' onClick={() => setSelectedInteractionType(interaction)}>
									<img className='h-20 w-20' src={getIconForElement(ElementTypeT.INTERACTION)(interaction)} alt='interaction' />
									<span className='text-xl'>{mapElementTypeToWords(interaction)}</span>
								</Card>
							))}
						</div>
					</div>
				</Flex>
			) : null}
			{selectedInteractionType && isEmpty(newInteractionTitle) ? <NewInteractionInput onTitleSubmit={handleSubmit} onGoBack={handleOnGoBack} /> : null}
		</>
	);
};
