import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { InteractionT, OpenEndedInteractionConfigurationT } from '../../../../types/Interaction.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { saveOpenEndedInteraction } from '../../service/InteractionService.ts';
import { AUTO_SAVE_DEBOUNCE_TIME, PLACE_HOLDER_MAX_LENGTH } from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const OpenEndedAnswerInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateOpenEndedInteraction = useUpdateInteraction(presentationId, interaction.id, (data: OpenEndedInteractionConfigurationT) =>
		saveOpenEndedInteraction(presentationId, interaction.id, data)
	);

	const conf = interaction.configuration as OpenEndedInteractionConfigurationT;

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<OpenEndedInteractionConfigurationT>({
		defaultValues: {
			required: interaction.required,
			maximumAnswers: conf.maximumAnswers,
			placeholder: conf.placeholder,
		},
	});

	const onSubmit: SubmitHandler<OpenEndedInteractionConfigurationT> = async (data) => {
		updateOpenEndedInteraction.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={interaction.id}
								elementType={ElementTypeT.INTERACTION}
								title={interaction.title}
								description={interaction.description}
							/>
							<FormControl mt={8}>
								<Input
									id='placeholder'
									fontSize={18}
									fontWeight='bold'
									size='lg'
									data-peer
									maxLength={PLACE_HOLDER_MAX_LENGTH}
									{...register('placeholder', {
										maxLength: PLACE_HOLDER_MAX_LENGTH,
									})}
								/>
								<FormLabel htmlFor='placeholder' variant='floating' size='lg'>
									Placeholder
								</FormLabel>
							</FormControl>
						</TabPanel>
						<TabPanel>
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<Flex>
								<FormControl mt={8}>
									<FormControl mt={8} isInvalid={!!errors.maximumAnswers}>
										<Input
											type='number'
											id='maximumAnswers'
											size='lg'
											placeholder=''
											data-peer
											min={1}
											max={10}
											required
											{...register('maximumAnswers', {
												required: true,
												min: 1,
												max: 10,
											})}
										/>
										<FormLabel htmlFor='maximumAnswers' variant='floating' size='lg'>
											{translate('Maximum number of answers')}
										</FormLabel>
									</FormControl>
								</FormControl>
							</Flex>
							<Box mt={8}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
