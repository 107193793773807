import { VisualizationT } from '../../../../types/Visualization.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export function PillsVisualization({ presentationId, visualization }: Props) {
	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<VisualizationInteractionCard interaction={visualization.interaction} />
			<form>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={visualization.id}
								elementType={ElementTypeT.VISUALIZATION}
								title={visualization.title}
								description={visualization.description}
							/>
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
}
