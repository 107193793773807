import React from 'react';
import { Card } from '../Card';

interface Props {
	iconImageSrc: string;
	label: string;
	onClick?: () => void;
	description?: string;
}

export const IconBox: React.FC<Props> = ({ iconImageSrc, label, description, ...props }) => {
	return (
		<Card className={`justify-center items-center flex flex-col w-65 h-65 cursor-pointer`} {...props}>
			<img src={iconImageSrc} alt={label} className='w-28 h-28' />
			<p className='text-xl mt-2 leading-[2.2rem]'>{label}</p>
			{description && <p className='text-md text-center'>{description}</p>}
		</Card>
	);
};
