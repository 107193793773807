import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Input, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { VisualizationT, WheelConfigurationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { saveWheelVisualization } from '../../service/VisualizationService.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { AUTO_SAVE_DEBOUNCE_TIME, WHEEL_VISUALIZATION_CHART_TITLE_MAX_CHARACTERS } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const WheelVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: WheelConfigurationT) =>
		saveWheelVisualization(presentationId, visualization.id, data)
	);
	const conf = visualization.configuration as WheelConfigurationT;

	const useFormMethods = useForm<WheelConfigurationT>({
		defaultValues: {
			chartTitle: conf.chartTitle,
		},
	});

	const { register, handleSubmit, watch } = useFormMethods;

	const onSubmit: SubmitHandler<WheelConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<VisualizationInteractionCard interaction={visualization.interaction} />
							<TitleForm
								presentationId={presentationId}
								elementId={visualization.id}
								elementType={ElementTypeT.VISUALIZATION}
								title={visualization.title}
								description={visualization.description}
							/>
							<Flex flexDirection='column'>
								<FormControl mt={8}>
									<Input
										id='chartTitle'
										fontSize={18}
										fontWeight='bold'
										size='lg'
										data-peer
										maxLength={WHEEL_VISUALIZATION_CHART_TITLE_MAX_CHARACTERS}
										{...register('chartTitle', {
											maxLength: WHEEL_VISUALIZATION_CHART_TITLE_MAX_CHARACTERS,
										})}
									/>
									<FormLabel htmlFor='chartTitle' variant='floating' size='lg'>
										Chart Title
									</FormLabel>
								</FormControl>
							</Flex>
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
