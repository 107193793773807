import {
	Button,
	Flex,
	Heading,
	IconButton,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Tooltip,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { deleteInteraction } from '../../../interaction/service/InteractionService.ts';
import { getClienAppSlideUrl } from '../../../presentation/service/LinkService.ts';
import { deleteVisualization } from '../../../visualization/service/VisualizationService.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import React from 'react';
import { deleteStaticContent } from '../../../staticContent/service/StaticContentService.ts';
import { InteractionT } from '../../../../types/Interaction.ts';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { StaticContentT } from '../../../../types/StaticContent.ts';
import { translate } from '../../../../translate';
import { useVisualizationsByInteraction } from '../../../interaction/hooks/useVisualizationsByInteraction.ts';
import { Loading } from '../Loading';
import { duplicateElement } from '../../../element/service/ElementService.ts';
import { IoMdEye } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { LuCopyPlus, LuMessagesSquare } from 'react-icons/lu';

interface Props {
	presentationId: string;
	element: InteractionT | VisualizationT | StaticContentT;
	elementType: ElementTypeT;
}

const NAME_MAPPING: Record<ElementTypeT, string> = {
	[ElementTypeT.INTERACTION]: translate('interaction'),
	[ElementTypeT.VISUALIZATION]: translate('visualization'),
	[ElementTypeT.STATIC_CONTENT]: translate('Content').toLowerCase(),
};

export const ElementMoreMenuV2: React.FC<Props> = ({ presentationId, element, elementType }) => {
	const navigate = useNavigate();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: visualizationsForInteraction, isLoading } = useVisualizationsByInteraction(presentationId, element.id, elementType === ElementTypeT.INTERACTION && isOpen);

	const handleDelete = () => {
		handleOnDeleteElement();
		onClose();
	};

	const handleOnDeleteElement = async () => {
		if (elementType === ElementTypeT.INTERACTION) {
			await deleteInteraction(presentationId, element.id);
		} else if (elementType === ElementTypeT.VISUALIZATION) {
			await deleteVisualization(presentationId, element.id);
		} else if (elementType === ElementTypeT.STATIC_CONTENT) {
			await deleteStaticContent(presentationId, element.id);
		}
		invalidatePresentationQuery();
		navigate(`/presentations/${presentationId}`);
	};

	const handleViewInBrowserClick = () => {
		const url = getClienAppSlideUrl(presentationId, element.slide.id);
		const finalUrl = element.slide.hidden ? `${url}/preview` : url;
		window.open(finalUrl, '_blank');
	};

	const handleDuplicate = async () => {
		const newElement = await duplicateElement(presentationId, element.id, elementType);
		invalidatePresentationQuery();
		navigate(`/presentations/${presentationId}/slides/${newElement.newSlideId}/new`);
	};

	const handleManageInteractionEntries = () => {
		if (elementType === ElementTypeT.INTERACTION) {
			navigate(`/presentations/${presentationId}/interactions/${element.id}/manage-entries`);
		} else if (elementType === ElementTypeT.VISUALIZATION) {
			const visualization = element as VisualizationT;
			navigate(`/presentations/${presentationId}/interactions/${visualization.interaction.id}/manage-entries`);
		}
	};

	return (
		<>
			<div className='flex'>
				<Tooltip label={translate('View slide')}>
					<IconButton aria-label='view slide' icon={<IoMdEye size='25' />} onClick={handleViewInBrowserClick} my={0} color='brand' />
				</Tooltip>

				<Tooltip label={translate('Duplicate')}>
					<IconButton aria-label='Duplicate' icon={<LuCopyPlus size='25' />} onClick={handleDuplicate} my={0} color='brand' />
				</Tooltip>

				{elementType === ElementTypeT.INTERACTION || elementType === ElementTypeT.VISUALIZATION ? (
					<Tooltip label={translate('Manage entries for interaction')}>
						<IconButton aria-label='Manage entries for interaction' icon={<LuMessagesSquare size='25' />} onClick={handleManageInteractionEntries} my={0} color='brand' />
					</Tooltip>
				) : null}

				<Tooltip label={`${translate('Delete')} ${NAME_MAPPING[elementType]}`}>
					<IconButton aria-label='Delete element' icon={<MdDeleteForever size='25' />} onClick={onOpen} color='brand' my={0} />
				</Tooltip>
			</div>
			<Modal isOpen={isOpen} size='xl' isCentered onClose={onClose}>
				<ModalOverlay />
				<ModalContent p={8}>
					<ModalCloseButton />
					<ModalHeader>
						<Text fontSize='2xl' textAlign='center'>
							{`${translate('You are about to delete')} ${NAME_MAPPING[elementType]}: ${element.title}`}
						</Text>
					</ModalHeader>
					<ModalBody m={0} p={0} textAlign='center' as={Flex} justifyContent='center' alignItems='center' flexDirection='column' gap={4}>
						<Heading m={0} pt={0} fontSize='sm'>
							{translate('Are you sure?')}
						</Heading>
						{isLoading ? <Loading size='md' /> : null}
						{visualizationsForInteraction && visualizationsForInteraction?.length > 0 ? <Text>{translate('The following associated visualizations will be removed')}</Text> : null}
						<UnorderedList maxH='20' overflowX='scroll' px={4}>
							{visualizationsForInteraction?.map((value) => (
								<ListItem key={value.id} my={2} fontWeight='bold'>
									{value.title}
								</ListItem>
							))}
						</UnorderedList>
					</ModalBody>
					<ModalFooter my={2} justifyContent='space-evenly'>
						<Button onClick={handleDelete} variant='submit'>
							{translate('Yes')}
						</Button>
						<Button onClick={onClose} variant='submit'>
							{translate('No')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
