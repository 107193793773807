import React from 'react';
import { VisualizationTypeT } from '../../../../../../../../types/Visualization.ts';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';
import { translate } from '../../../../../../../../translate';
import { Card } from '../../../../../../../commons/components/Card';
import { getIconForElement, mapElementTypeToWords } from '../../../../../../../../util/InteractionUtils.ts';
import { ElementTypeT } from '../../../../../../../../types/Presentation.ts';

export interface NewVisualizationValues {
	type: VisualizationTypeT;
}

interface Props {
	supportedVisualizations: VisualizationTypeT[];
	showTitle: boolean;
	onSelectedVisualization: (newVisualization: NewVisualizationValues) => void;
	onGoBack: () => void;
}

export const NewVisualization: React.FC<Props> = ({ supportedVisualizations, onSelectedVisualization, showTitle, onGoBack }) => {
	const handleSubmit = (visualizationType: VisualizationTypeT) => {
		if (visualizationType) {
			onSelectedVisualization({
				type: visualizationType,
			});
		}
	};

	return (
		<>
			{showTitle ? <NewElementHeader title={translate('What type of visualization do you want to create?')} onGoBack={onGoBack} /> : null}
			<div className='flex justify-center items-center'>
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8'>
					{supportedVisualizations.map((visualization) => (
						<Card key={visualization} className='flex gap-4 justify-start items-center hover:cursor-pointer min-w-65' onClick={() => handleSubmit(visualization)}>
							<img className='h-20 w-20' src={getIconForElement(ElementTypeT.VISUALIZATION)(visualization)} alt='interaction' />
							<span className='text-xl'>{mapElementTypeToWords(visualization)}</span>
						</Card>
					))}
				</div>
			</div>
		</>
	);
};
