import { Link as RouterLink } from 'react-router';
import { PresentationTitle } from '../../../presentation/components/PresentationTitle';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { PresentationSettings } from '../../../presentation/components/PresentationSettings';
import { translate } from '../../../../translate';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { EmbedPresentation } from '../../../presentation/components/EmbedPresentation';
import { ThemePicker } from '../../../theming/components/ThemePicker';
import { Button } from '@chakra-ui/react';
import { LuMonitorPlay } from 'react-icons/lu';

interface Props {
	presentationId: BasicPresentationT['id'];
	presentationTitle?: BasicPresentationT['title'];
	onViewPresentationInFrontend: () => void;
}

export function TopNavigation({ presentationId, presentationTitle, onViewPresentationInFrontend }: Props) {
	return (
		<nav>
			<div className='flex justify-between items-center h-20'>
				<div className='flex items-center w-4/5 mx-2'>
					<RouterLink to='/presentations' className='mx-3 text-xl'>
						<MdOutlineArrowBackIos />
					</RouterLink>
					<PresentationTitle presentationId={presentationId} currentTitle={presentationTitle ?? 'Untitled'} />
				</div>
				<ThemePicker presentationId={presentationId} />
				<PresentationSettings presentationId={presentationId} />
				<EmbedPresentation presentationId={presentationId} presentationTitle={presentationTitle ?? 'Untitled'} />
				<div className='flex items-center mx-4'>
					<Button rightIcon={<LuMonitorPlay />} variant='submit' onClick={onViewPresentationInFrontend}>
						{translate('Play')}
					</Button>
				</div>
			</div>
		</nav>
	);
}
