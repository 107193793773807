import { PLAYER_AVAILABLE_FONTS } from '../../../../../types/Constants.ts';
import { useToken } from '@chakra-ui/react';
import { capitalizeFirstWord } from '../../../../commons/utils/StringUtils.ts';

interface Props {
	selectedFont: string;
	onChange: (font: string) => void;
}

export function ThemeFontPicker({ selectedFont, onChange }: Props) {
	const [brand, fontColor, selectedColor] = useToken('colors', ['brand', 'fontColor', 'selectedColor']);
	return (
		<div className='flex-col items-center'>
			<label htmlFor='font' className='text-2xl font-bold'>
				Font:
			</label>
			<div className='flex gap-4 mt-4'>
				{PLAYER_AVAILABLE_FONTS.map((font) => {
					const isSelected = selectedFont === font;

					return (
						<div
							key={font}
							className='flex items-center justify-center rounded-2xl h-20 w-40 cursor-pointer'
							style={{ backgroundColor: isSelected ? selectedColor : brand, color: fontColor }}
							onClick={() => {
								onChange(font);
							}}
						>
							<span className='text-xl text-center text-wrap p-2 font-semibold' style={{ fontFamily: font }}>
								{capitalizeFirstWord(font)}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
}
