import { InteractionTypeT } from './Interaction';
import { VisualizationTypeT } from './Visualization';
import { StaticContentTypeT } from './StaticContent.ts';

// export interface PresentationT {
//   id: number;
//   title: string;
//   description: string;
//   status: PresentationStatusT;
//   lastModifiedDate: Date;
//   slides?: SlideT[];
// }

// export interface SlideT {
//   id: number;
//   index: number;
//   element: ElementT;
// }

// export interface ElementT {
//   id: number;
//   label: string;
//   elementType: ElementTypeT;
//   configuration: ElementConfigurationT;
// }

// export interface ElementConfigurationT {}

export enum ElementTypeT {
	INTERACTION = 'INTERACTION',
	VISUALIZATION = 'VISUALIZATION',
	STATIC_CONTENT = 'STATIC_CONTENT',
}

/**
 * presentations list
 */

export interface PresentationListT {
	id: string;
	title: string;
	lastModifiedDate: string;
}

/**
 * Elements for slides and presentation details
 */
export interface BasicPresentationT {
	id: string;
	title: string;
	description: string;
	hideProgressBar: boolean;
	hideControls: boolean;
	closeParticipations: boolean;
	slides: BasicSlideT[];
}

export interface BasicSlideT {
	id: string;
	index: number;
	title: string;
	element: BasicElementT;
}

export interface BasicElementT {
	id: string;
	title: string;
	description: string;
	elementType: ElementTypeT;
	type?: InteractionTypeT | VisualizationTypeT | StaticContentTypeT;
}
