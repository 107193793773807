import React from 'react';
import { useAuth } from '../../../auth/FirebaseAuthProvider/FirebaseAuthProvider.tsx';
import { MdLogout } from 'react-icons/md';
import { useRemovePresentationList } from '../../hooks/usePresentationList.ts';
import { QuestioryLogo } from '../../../../components/QuestioryLogo';
import { IconButton } from '../../../commons/components/IconButton';

interface Props {}

export const DefaultMenu: React.FC<Props> = () => {
	const { currentUser, logout } = useAuth();
	const removePresentationList = useRemovePresentationList();
	const handleLogout = async () => {
		removePresentationList();
		await logout();
	};

	return (
		<nav className='w-full flex justify-between px-16 pt-8'>
			<div className='self-center'>
				<QuestioryLogo />
			</div>
			<div className='flex flex-row justify-end items-center'>
				<span>{currentUser?.email}</span>
				<IconButton aria-label='logout' icon={<MdLogout />} className='bg-transparent!' onClick={handleLogout} />
			</div>
		</nav>
	);
};
