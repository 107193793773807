import { ReactNode } from 'react';

interface Props {
	text: string;
	children?: ReactNode;
}

export function Tooltip({ text, children }: Props) {
	return (
		<div className='relative group'>
			{children}
			<div className='absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:flex px-3 py-1 bg-gray-800 text-white text-sm rounded-md shadow-md z-10 whitespace-nowrap transition-opacity duration-200'>
				{text}
			</div>
		</div>
	);
}
