import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { SimpleMathConfigurationT, VisualizationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { saveSimpleMathVisualization } from '../../service/VisualizationService.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { RadioButton, RadioButtonGroup } from '../../../commons/components/RadioButtonGroup/RadioButtonGroup.tsx';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const SimpleMathVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: SimpleMathConfigurationT) =>
		saveSimpleMathVisualization(presentationId, visualization.id, data)
	);
	const conf = visualization.configuration as SimpleMathConfigurationT;

	const { register, handleSubmit, watch, control } = useForm<SimpleMathConfigurationT>({
		defaultValues: {
			operation: conf.operation,
			reference: conf.reference,
		},
	});

	const onSubmit: SubmitHandler<SimpleMathConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>{translate('Content')}</Tab>
						<Tab fontSize={20}>{translate('Settings')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<VisualizationInteractionCard interaction={visualization.interaction} />
							<TitleForm
								presentationId={presentationId}
								elementId={visualization.id}
								elementType={ElementTypeT.VISUALIZATION}
								title={visualization.title}
								description={visualization.description}
							/>
						</TabPanel>
						<TabPanel>
							<Flex flexDirection='column' mt={4}>
								<Text fontSize='lg'>Operation</Text>
								<Box as='section' bg='bg-surface'>
									<Controller
										name='operation'
										control={control}
										rules={{ required: true }}
										render={({ field: { ...field } }) => (
											<RadioButtonGroup defaultValue='left' size='xl' {...field}>
												<RadioButton value={'SUM'}>Sum</RadioButton>
												<RadioButton value={'AVG'}>Average</RadioButton>
											</RadioButtonGroup>
										)}
									/>
								</Box>
							</Flex>
							<FormControl my={5}>
								<Input type='text' size='lg' placeholder=' ' id='numberreference' data-peer {...register('reference')} />
								<FormLabel htmlFor='numberreference' variant='floating' size='lg'>
									Number reference
								</FormLabel>
							</FormControl>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
