import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { SearchInteractionResult } from '../../../../../../../interaction/service/InteractionService.ts';
import { useInvalidatePresentationQuery } from '../../../../../../../presentation/hooks/usePresentation.ts';
import { Loading } from '../../../../../../../commons/components/Loading';
import { NewVisualization } from '../../../NewDataVisualization/_/NewVisualization';
import { NewVisualizationValues } from '../../../NewDataVisualization/_/NewVisualization/NewVisualization.tsx';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';
import { createVisualization } from '../../../../../../../element/service/ElementService.ts';
import { TagLabel } from '../../../../../../../commons/components/TagLabel';

interface Props {
	presentationId: string;
	interaction: SearchInteractionResult;
	onGoBack: () => void;
}

export const VisualizationPicker: React.FC<Props> = ({ presentationId, interaction, onGoBack }) => {
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnSubmit = async (newVisualization: NewVisualizationValues) => {
		setIsLoading(true);
		const newSlide = await createVisualization(presentationId, {
			title: interaction.title,
			type: newVisualization.type,
			existingInteractionId: interaction.id,
		});
		invalidatePresentationQuery();
		navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<NewElementHeader title='Creating a visualization for interaction' onGoBack={onGoBack} />
			<div className='flex justify-center mb-8'>
				<TagLabel className='mx-12'>
					<span className='text-lg'>{interaction.title}</span>
				</TagLabel>
				{/*<Tag size='lg' backgroundColor='colorScheme.100' mx={10} textColor='textColor'>*/}
				{/*	/!*<Image src={getIconForElement(ElementTypeT.INTERACTION)(interaction.type)} boxSize='8' alt={`${interaction.type} Interaction`} />*!/*/}
				{/*	<TagLabel fontSize='1.5rem' textColor='textColor'>*/}
				{/*		{interaction.title}*/}
				{/*	</TagLabel>*/}
				{/*</Tag>*/}
			</div>
			<div className='flex flex-col justify-center items-center'>
				<NewVisualization supportedVisualizations={interaction.supportedVisualizations} onSelectedVisualization={handleOnSubmit} onGoBack={onGoBack} showTitle={false} />
			</div>
		</>
	);
};
