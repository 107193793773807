import { ReactNode } from 'react';

interface Props {
	children: ReactNode;
	className?: string;
}

export function TagLabel({ className, children }: Props) {
	return <div className={`inline-flex items-center bg-gray-100 p-2 rounded-4xl px-6 ${className}`}>{children}</div>;
}
